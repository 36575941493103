









import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import GameDialogue from '@/components/GameDialogue.vue'
import GameHud from '@/components/GameHud.vue'
import GameMap from '@/components/GameMap.vue'
import Curtain from '@/components/Curtain.vue'
import whatsThePassword from '@/assets/dialogues/entrance.js'

export default Vue.extend({
  name: 'Entrance',
  components: {
    GameDialogue,
    GameHud,
    GameMap,
    Curtain
  },
  props: {
  },
  data () {
    return {
      curtainActive: false,
      curtainHeading: 'Entrance',
      whatsThePassword: whatsThePassword
    }
  },
  computed: {
    ...mapGetters([
      'hintsRemaining'
    ]),
  },
  methods: {
    ...mapActions([
      'loseHint',
      'refreshHints',
      'completeStage'
    ]),
    handleHudClick (action: string) {
      if (typeof this[action] === 'function') {
        this[action]()
      }
    },
    // showMap () {
    //   this.$refs.gameMap.show()
    // },
    onCurtainClick () {
      this.curtainActive = false
    },
    loseHintFromDialogue () {
      console.log('Entrance.loseHintFromDialogue')
      if (this.hintsRemaining === 3) {
        this.$nextTick(()=>{
          this.$refs.gameDialogue.goto('node20')
        })
      }
      if (this.hintsRemaining === 1) {
        this.$nextTick(()=>{
          this.$refs.gameDialogue.goto('node40')
        })
      }
      this.loseHint()
    },
    enterlobby () {
      this.completeStage('Entrance')
      this.refreshHints()
      this.$router.push('/lobby')
    }
  },
  mounted () {
    window.setTimeout(()=>{
      this.curtainActive = false
    }, 500)
  }
});
