export default {
  start: {
    type: "auto",
    delay: 1000,
    goto: "node00"
  },
  node00: {
    type: "node",
    speaker: "",
    text: "To start the game, you must first make it past the entrance, but the door is guarded by a security officer who is asking each participant who wants to enter for a password.<br><br>You decide to hang out near the entrance to see if you can get some clue about what the password might be.",
    options: [
      {
        text: "Next &rarr;",
        goto: "node01",
        transcribe: false
      },
      // {
      //   text: "(Skip to Lobby).",
      //   goto: "end01",
      //   emit: 'enterlobby'
      // },
    ]
  },
  node01: {
    type: "node",
    speaker: "",
    text: 'As a participant comes to the door, the security officer says, "Twelve". She replies, "Six" and get in.',
    options: [
      {
        text: "Next &rarr;",
        goto: "node02",
        transcribe: false
      },
    ]
  },
  node02: {
    type: "node",
    speaker: "",
    text: 'Another participant comes to the door. The security officer says, "Six", and the man replies, "Three" and gets in.',
    options: [
      {
        text: "Next &rarr;",
        goto: "node10",
        transcribe: false
      },
      // {
      //   text: "Keep waiting.",
      //   goto: "node03"
      // },
      // {
      //   text: "Attempt to enter.",
      //   goto: "node10"
      // },
    ]
  },
  // node03: {
  //   type: "node",
  //   speaker: "",
  //   text: 'The security officer notices you and starts to get suspicious.',
  //   options: [
  //     {
  //       text: "Approach the door.",
  //       goto: "node10"
  //     },
  //   ]
  // },
  node10: {
    type: "node",
    speaker: "",
    text: 'The security officer now says to you, "Ten." What\'s the password?',
    options: [
      {
        text: "Three.",
        goto: "node30"
      },
      {
        text: "Five.",
        goto: "node11",
        emit: 'losehint'
      },
      {
        text: "Nine.",
        goto: "node12",
        emit: 'losehint'
      },
      {
        text: "Twelve.",
        goto: "node13",
        emit: 'losehint'
      },
    ]
  },
  node11: {
    type: "node",
    speaker: "",
    text: '"That\'s incorrect," they reply. After a moment they repeat, "Ten."',
    options: [
      {
        text: "Three.",
        goto: "node30"
      },
      {
        text: "Nine.",
        goto: "node12",
        emit: 'losehint'
      },
      {
        text: "Twelve.",
        goto: "node13",
        emit: 'losehint'
      },
    ]
  },
  node12: {
    type: "node",
    speaker: "",
    text: '"That\'s incorrect," they reply. After a moment they repeat, "Ten."',
    options: [
      {
        text: "Three.",
        goto: "node30"
      },
      {
        text: "Five.",
        goto: "node11",
        emit: 'losehint'
      },
      {
        text: "Twelve.",
        goto: "node13",
        emit: 'losehint'
      },
    ]
  },
  node13: {
    type: "node",
    speaker: "",
    text: '"That\'s incorrect," they reply. After a moment they repeat, "Ten."',
    options: [
      {
        text: "Three.",
        goto: "node30"
      },
      {
        text: "Five.",
        goto: "node11",
        emit: 'losehint'
      },
      {
        text: "Nine.",
        goto: "node12",
        emit: 'losehint'
      },
    ]
  },
  node20: {
    type: "node",
    speaker: "",
    text: 'While you\'re thinking. A third participant walks up. The security officer says, "Blue." The participant replies with "Four" and gets in.<br><br>Looking back at you, the security officer again says, "Ten."',
    options: [
      {
        text: "Three.",
        goto: "node30"
      },
      {
        text: "Five.",
        goto: "node40",
        emit: 'losehint'
      },
      {
        text: "Nine.",
        goto: "node40",
        emit: 'losehint'
      },
      {
        text: "Twelve.",
        goto: "node40",
        emit: 'losehint'
      },
      {
        text: "Blue.",
        goto: "node40",
        emit: 'losehint'
      },
      {
        text: "Hey! Look over there!",
        goto: "node40",
        emit: 'losehint'
      },
    ]
  },
  node30: {
    type: "node",
    speaker: "",
    text: '"That is correct." The security officer lets you pass.',
    options: [
      {
        text: "Next &rarr;",
        goto: "node50",
        transcribe: false
      },
    ]
  },
  node40: {
    type: "node",
    speaker: "",
    text: 'The security officer radios in your failed attempt. But since this was a training exercise, you get in anyway.',
    options: [
      {
        text: "Next &rarr;",
        goto: "node50",
        transcribe: false
      },
    ]
  },
  node50: {
    type: "node",
    speaker: "",
    class: "informational",
    text: "You should always be aware of your surroundings when verbalizing sensitive information like customer data or your passwords - nearby malicious actors can listen and copy or learn in order to conduct malicious activity.<br><br>One technique for getting a person's password is to simply stand behind that individual and watch them enter their password into their computer or device. This is called shoulder surfing.",
    options: [
      {
        text: "Enter lobby.",
        goto: "end01",
        emit: 'enterlobby'
      },
    ]
  },
  end01: {
    type: "end",
  },
}
