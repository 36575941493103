



















import Vue from 'vue';

export default Vue.extend({
  name: 'Dialogue',
  props: {
    tree: {
      type: Object,
      default: () => {
        return {
          start: {
            type: "auto",
            delay: 1000,
            goto: "node00"
          },
          node00: {
            type: "node",
            speaker: "Speaker",
            text: "Text.",
            options: [
              {
                text: "Continue.",
                goto: "node01"
              },
              {
                text: "End.",
                goto: "end01"
              },
            ]
          },
          node01: {
            type: "node",
            speaker: "Speaker",
            text: "More text.",
            options: [
              {
                text: "Continue.",
                goto: "node01"
              },
              {
                text: "End.",
                goto: "end01"
              },
            ]
          },
          end01: {
            type: "end",
          },
        }
      }
    }
  },
  data () {
    return {
      atNode: 'start',
      speaker: '',
      text: '',
      options: [],
      animationTimer: null,
      animationTime: 7,
      transcript: []
    }
  },
  computed: {
    currentNode () {
      return (this.atNode && this.tree.hasOwnProperty(this.atNode)) ? this.tree[this.atNode] : {type: 'error'}
    },
    currentSpeaker () {
      return (this.currentNode.type==='node') ? this.currentNode.speaker : ''
    },
    currentSpeakerText () {
      return (this.currentNode.type==='node') ? this.currentNode.text : ''
    },
    currentNodeClass () {
      return (this.currentNode.hasOwnProperty('class')) ? this.currentNode.class : ''
    },
    responseOptions () {
      return (this.currentNode.type==='node') ? this.currentNode.options : null
    },
    dialogueEnded () {
      return (this.currentNode.type==='end')
    },
  },
  methods: {
    nextNode (option) {
      if (option && option.hasOwnProperty('text')) {
        if (!(option.hasOwnProperty('transcribe') && option.transcribe===false)) {
          this.transcript.push({
            class: 'transcript-response',
            text:  option.text
          })
        }
      }
      if (option.hasOwnProperty('emit')) {
        this.$emit(option.emit)
      }
      let delay = 0
      if (option.hasOwnProperty('delay')) {
        delay = option.delay
      }
      this.animationTimer = window.setTimeout(() => {
        this.atNode = option.goto
        this.animate()
      }, delay)
    },
    goto (node) {
      if (this.animationTimer) window.clearTimeout(this.animationTimer)
      if (this.tree.hasOwnProperty(node)) {
        this.atNode = node
        this.animate()
        return true
      }
      return false
    },
    animate () {
      this.options = []
      this.animationTimer = window.setTimeout(this.animateSpeaker, this.animationTime)
    },
    animateSpeaker () {
      if (this.speaker.length < this.currentSpeaker.length) {
        this.speaker = this.currentSpeaker.substring(0, this.speaker.length+1)
        this.animationTimer = window.setTimeout(this.animateSpeaker, this.animationTime)
      } else {
        this.transcript.push({
          class: this.currentNodeClass,
          text:  this.speaker
        })
        this.speaker = ''
        this.animateText()
      }
    },
    animateText () {
      if (this.text.length < this.currentSpeakerText.length) {
        this.text = this.currentSpeakerText.substring(0, this.text.length+1)
        this.animationTimer = window.setTimeout(this.animateText, this.animationTime)
      } else {
        this.transcript.push({
          class: this.currentNodeClass,
          text:  this.text
        })
        this.text = ''
        this.animateOptions()
      }
    },
    animateOptions () {
      if (this.responseOptions) {
        this.options = this.responseOptions
      }
    },
  },
  mounted () {
    console.log('GameDialoge.mounted', this.currentNode.type)
    if (this.currentNode.type==='auto') {
      this.nextNode(this.currentNode)
    }
  }
});
